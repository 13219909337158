import $ from 'jquery'

import "@claviska/jquery-minicolors/jquery.minicolors.js"
import "@claviska/jquery-minicolors/jquery.minicolors.css"

document.addEventListener("DOMContentLoaded", () => {
  const els = $("input[data-minicolors]")
  if (els.minicolors) {
    els.minicolors({
      theme: 'bootstrap',
      position: 'left'
    })
  }
})
