import "@/main/index.scss"

import "bootstrap"
import "plugins/fa.js"

import "@pludoni/frontend-pack/src/vue/axios"

import "@/main/modules/landingpage-charts"
import "@/main/modules/reset-defaults"
import "@/main/modules/color-picker"
import "@/main/modules/cxp"
import "utils/cookies"

import Rails from "@rails/ujs"

if (!window._rails_loaded) {
  Rails.start()
}

import Vue from "vue"
import { ToastPlugin } from "bootstrap-vue"

Vue.use(ToastPlugin)

import Automount from "@pludoni/frontend-pack/src/vue/automount"

Automount({
  "user-sidebar": {
    component: () => import("main/components/UserSidebar.vue"),
    extra: {},
  },
  "service-provider-input": {
    component: () => import("main/components/ServiceProviderInput.vue"),
  },
  "department-selector": {
    component: () => import("main/components/DepartmentSelector.vue"),
  },
  "location-app": {
    component: () => import("main/components/locations/LocationApp.vue"),
  },
  "admin-channel-hierarchy": {
    component: () =>
      import("admin/components/hierarchy/AdminChannelHierarchy.vue"),
    extra: {},
  },
  "admin-department-hierarchy": {
    component: () =>
      import("admin/components/hierarchy/AdminDepartmentHierarchy.vue"),
    extra: {},
  },
})
