import $ from "jquery"

export default (radio, toggleValue, toggleElement) => {
  radio
    .change(() => {
      let vals

      if (radio.is("select")) {
        vals = radio
          .find("option:selected")
          .map(function () {
            return $(this).val()
          })
          .toArray()
      } else {
        vals = radio
          .filter(":checked")
          .map(function () {
            return $(this).val()
          })
          .toArray()
      }

      let shouldValues = []

      if (typeof toggleValue !== "object") {
        shouldValues = [toggleValue]
      } else {
        shouldValues = toggleValue
      }

      let found = false
      shouldValues.forEach((val) => {
        if (vals.indexOf(val.toString()) !== -1) {
          found = true
        }
      })

      if (found) {
        toggleElement.collapse("show")
      } else {
        toggleElement.collapse("hide")
      }

      setTimeout(() => toggleElement.removeClass("collapsing"), 500)

      if (window.sr) {
        window.sr.sync()
      }
    })
    .change()
}
