import $ from '@pludoni/frontend-pack/src/plugins/jquery'


document.addEventListener("DOMContentLoaded", () => {
  $("input[data-default],textarea[data-default]").each(function () {
    const el = $(this)
    if (el.closest(".input-group").length > 0) return
    const button = $(`
        <a class='btn reset pull-right'><i class='fa fa-fw fa-repeat'></i> Zurücksetzen</a>
        `)
    button.insertAfter(el)
    button.click((event) => {
      event.preventDefault()
      el.val(el.data("default"))
    })
  })
})
