import $ from '@pludoni/frontend-pack/src/plugins/jquery'
import Highcharts from '@pludoni/frontend-pack/src/vue/highcharts'

const drawCharts = function () {
  const width = $(".services-tab-content").first().width()
  const el = $("#bar-chart-gender")
  if (el.length === 0) {
    return
  }
  Highcharts.chart("bar-chart-gender", {
    chart: {
      width,
      type: "bar",
    },
    title: {
      text: $("#bar-chart-gender").data("chartTitle"),
    },
    xAxis: {
      title: {
        text: $("#bar-chart-gender").data("xAxis"),
      },
      categories: $("#bar-chart-gender").data("categories"),
    },
    plotOptions: {
      series: {
        stacking: "normal",
      },
    },
    tooltip: {
      formatter() {
        return `<b>${this.x}</b>: ${Math.abs(this.y)}%`
      },
    },
    credits: {
      enabled: false,
    },
    legend: false,
    yAxis: {
      plotBands: [
        {
          color: "rgba(0,0,0,0.0)",
          from: -25,
          to: 0,
          zIndex: 2,
          label: {
            style: { fontSize: "22px", color: "#aaa" },
            text: $("#bar-chart-gender").data("male"),
          },
        },
        {
          color: "rgba(0,0,0,0.0)",
          from: 0,
          to: 25,
          zIndex: 2,
          label: {
            style: { fontSize: "22px", color: "#aaa" },
            text: $("#bar-chart-gender").data("female"),
          },
        },
      ],
      min: -25,
      max: 25,
      title: {
        text: "",
      },
      labels: {
        floating: true,
        formatter() {
          return `${Math.abs(this.value)}%`
        },
      },
    },
    series: [
      {
        name: $("#bar-chart-gender").data("male"),
        color: "#6e9091",
        data: [-1, -1.4, -2.6, -2.8, -4, -6.2, -11.4, -12.7, -11.6, -4.8],
      },
      {
        name: $("#bar-chart-gender").data("female"),
        color: "#6EABAD",
        data: [0.3, 0.8, 1.8, 2.3, 4.3, 6.7, 9.8, 11, 10.8, 3.3],
      },
    ],
  })
  Highcharts.chart("bar-chart", {
    chart: {
      width,
      type: "bar",
    },
    title: {
      text: $("#bar-chart").data("chartTitle"),
    },
    xAxis: {
      categories: $("#bar-chart").data("categories"),
    },
    tooltip: {
      formatter() {
        return `<b>${this.x}</b>: ${this.y}%`
      },
    },
    credits: {
      enabled: false,
    },
    legend: false,
    yAxis: {
      min: 0,
      max: 25,
      title: {
        text: "",
      },
      labels: {
        floating: true,
        formatter() {
          return `${this.value}%`
        },
      },
    },
    series: [
      {
        color: "#6EABAD",
        data: [20.4, 15.8, 13.4, 6.6, 4.3],
      },
    ],
  })
  return Highcharts.chart("polar-chart", {
    chart: {
      width,
      polar: true,
      type: "line",
    },
    credits: {
      enabled: false,
    },
    title: {
      text: $("#polar-chart").data("chartTitle"),
    },
    xAxis: {
      categories: $("#polar-chart").data("categories"),
      tickmarkPlacement: "on",
      lineWidth: 0,
    },
    plotOptions: {
      center: ["50%", "50%"],
      spline: {
        lineWidth: 4,
      },
      line: {
        lineWidth: 4,
      },
    },
    yAxis: {
      gridLineInterpolation: "polygon",
      tickInterval: 1,
      min: 0,
      max: $("#polar-chart").data("keys").length,
      labels: {
        floating: true,
        formatter() {
          return $("#polar-chart").data("keys")[this.value]
        },
        style: {
          fontSize: 8,
          color: "#bfbfbf",
        },
      },
    },
    tooltip: {
      shared: true,
      pointFormatter() {
        return `<span style="color:${this.series.color}">${
          this.series.name
        }: <b>${$("#polar-chart").data("keys")[Math.floor(this.y)]} (${
          this.y
        })</b><br/>`
      },
    },
    series: [
      {
        name: $("#polar-chart").data("benchmark"),
        color: "#532080",
        data: [3.4, 2.1, 1.5, 4.0, 2.9],
        pointPlacement: "on",
      },
      {
        name: $("#polar-chart").data("own"),
        color: "#6EABAD",
        data: [4.4, 3.1, 2.5, 4.0, 3.9],
        pointPlacement: "on",
      },
    ],
  })
}

window.activateLandingpage = () => {
  $(".services-tab-item").first().addClass("active")
  $(".services-tab-content").first().addClass("active")
  drawCharts()
  $(".services-tab-item").click(function () {
    let id
    id = $(this).data("id")
    $(".services-tab-item").removeClass("active")
    $(".services-tab-content").removeClass("active")
    $(`#services-tab-item-${id}`).addClass("active")
    $(`#services-tab-content-${id}`).addClass("active")
  })
}
