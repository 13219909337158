import $ from "jquery"
import ScrollReveal from "scrollreveal"

import setConditionalToggle from "utils/setConditionalToggle"

window.setConditionalToggle = setConditionalToggle

window.sr = ScrollReveal()

window.revealFormFields = () => {
  $(() => {
    // window.sr.reveal("form .form-group", {
    //   duration: 900,
    //   delay: 300,
    //   distance: "100px",
    //   viewFactor: 0.2,
    //   scale: 1.0,
    // })
    // setTimeout(() => {
    //   $(document).scroll()
    //   window.sr.sync()
    // }, 350)
  })
}

$(document).on("change", ".js-yes input", () => $(".js-yes-group").each(function () {
  const group = $(this)
  let value = group.find(".js-yes input:checked").val()

  if (/^\d+$/.test(value)) {
    value = parseInt(value, 10)
  }

  const boolValue = value != null && (value > 0 || value === "true")
  const all = group.find(".collapse")
  const hide = []
  const show = []

  all.each(function () {
    let active
    const el = $(this)
    const checkValue = el.data("js-on")

    if (typeof checkValue === "object") {
      active = checkValue.indexOf(value) >= 0
    } else {
      active = checkValue === boolValue

      if (value === undefined) {
        active = false
      }
    }

    const isActive = el.hasClass("in")

    if (active && !isActive) {
      show.push(el)
    }

    if (!active && isActive) {
      hide.push(el)
    }

    let timeout = 0

    if (hide.length > 0) {
      timeout = 200

      $.each(hide, (_i, el) => el.removeClass("in"))
    }

    setTimeout(() => $.each(show, (_i, el) => el.addClass("in")), timeout)
  })
}))

document.addEventListener("DOMContentLoaded", () => {
  if ($(".js-yes").length > 0) {
    $(".js-yes input").first().change()
  }

  $(".js-show-on").each(function () {
    const el = $(this)
    el.removeClass("in")
    const target = $(`[name*=${el.data("condName")}]`)
    setConditionalToggle(target, el.data("condValue"), el)
  })
})

window.runJsHide = () => {
  $(".js-hide").collapse("hide").removeClass("in").removeClass("js-hide")

  $(() => setTimeout(() => $("#auto-hide").remove(), 300))
}
